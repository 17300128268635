import React from 'react';
import HomePage from './componets/Main';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
      <div className="App">
        <HomePage />
      </div>
  );
}

export default App;
